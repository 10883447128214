
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

var oldConnectionType = "none";
window.FileObject = File;

window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction
|| {READ_WRITE: "readwrite"}; // This line should only be needed if it is needed to support the object's constants for older browsers
window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;

function getScreenConstraints(sendSource, callback) {
  var screen_constraints = {};
  // now invoking native getUserMedia API
  callback(null, screen_constraints);
}

document.addEventListener('pause', function () {
  window.appInBackground = true;

});
document.addEventListener('resume', function () {
  window.appInBackground = false;

});

document.addEventListener('online', function() {
 
  oldConnectionType = navigator.connection.type;
});

document.addEventListener('offline', function() {

  oldConnectionType = navigator.connection.type;
});

function hasClass(elem, className) {
  return elem.classList.contains(className);
}

document.addEventListener('deviceready', function () {

  cordova.plugins.backgroundMode.setDefaults({
    title: 'VNCcommander',
    icon: 'icon',
    text: 'VNCcommander is running',
    silent: true
  });

  cordova.plugins.backgroundMode.enable();
  // cordova.plugins.backgroundMode.on('activate', function () {
 
  //   // cordova.plugins.backgroundMode.disableWebViewOptimizations();
  // });

  try {
    FastClick.attach(document.body);
  
  } catch (e) {

  }
  try {
    navigator.noAutolock.disableAutolock();
  } catch (e) {
  }
  if (typeof device !== 'undefined') {
    if (device.platform === 'iOS') {
      try {
        window.FirebasePlugin.grantPermission();
      } catch (e) {

      }
      document.querySelector('html').classList.remove('safari');
      document.querySelector('html').classList.add('vnccommandersafari');
    }
    if (device.platform === 'Android') {
      document.querySelector('html').classList.remove('chrome');
      document.querySelector('html').classList.add('vnccommanderchrome');
      }
  }

  document.addEventListener('exitButton', function () {

    navigator.notification.confirm(
      'Do you want to quit',
      onConfirmQuit,
      'QUIT TITLE',
      'OK,Cancel'
    );

  }, true);

  function onConfirmQuit(button) {
    if (button === '1') {
      navigator.app.exitApp();
      var myEvent = new CustomEvent('logoutXmpp', {
        detail: e.data
      });
      document.querySelector('body').dispatchEvent(myEvent);
    }
  }

}, false);

if (typeof Notification !== "undefined") {
  var ChromeNotification = Notification;
}
